import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { zxcvbn } from '@zxcvbn-ts/core';

export interface KurzPasswordSecurityLevel {
  score: number;
  hintTranslationKey?: string;
}

@Component({
  selector: 'app-kurz-password-checker',
  templateUrl: './kurz-password-checker.component.html',
  styleUrls: ['./kurz-password-checker.component.scss'],
})
export class KurzPasswordCheckerComponent {

  protected _control: FormControl;
  private readonly translationPreffix = 'customResetPassword.formular.hints.';
  private readonly zxcvbnSuffixes: string[] = ['tooWeak', 'weak', 'medium', 'secure', 'verySecure'];
  public passwordState$: Observable<KurzPasswordSecurityLevel | null>;

  @Input()
  set control(control: FormControl) {
    this._control = control;

    this.passwordState$ = this._control?.valueChanges.pipe(
      map(input => this.checkPassword(input)),
      tap(state => this.validPassword.emit(state?.score > 0))
    );
  }

  get control() {
    return this._control;
  }

  @Output() validPassword = new EventEmitter<boolean>(false);

  constructor() {}

  private checkPassword(input: string): KurzPasswordSecurityLevel | null {

    if (!input || input.length < 8) {
      return null;
    }

    if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(input)) {
      return {
        hintTranslationKey: this.translationPreffix + 'tooWeak',
        score: 0
      };
    }

    let evaluation = zxcvbn(input);
    let score = evaluation.score ? evaluation.score : 0 ;
    return {
      hintTranslationKey: this.translationPreffix + this.zxcvbnSuffixes[score],
      score: score
    };
  }

}
